import React from 'react';
import { Routes, Route } from "react-router-dom";
import { ThemeProvider } from '@mui/material/styles';
import theme from './themes/default';
import CssBaseline from '@mui/material/CssBaseline';

import LuckySpin from 'features/luckySpin/LuckySpin';

export default function App(props) {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        <Route path="/">
          <Route path="" element={<LuckySpin />} />
          <Route path=":luckySpinId/:ticketId" element={<LuckySpin />} />
          <Route path=":apiPort/:luckySpinId/:ticketId" element={<LuckySpin />} />
        </Route>
      </Routes>
    </ThemeProvider>
  );
}
