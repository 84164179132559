const wheelConfig = {
  background: '/images/wheels/cny/bg3.png',
  blocks: [
    {
      padding: '22px',
      imgs: [{
        src: '/images/wheels/cny/bg4.png',
        top: '0px',
        left: '0px',
        width: '100%',
        height: '100%',
        rotate: true
      }]
    },
    {
      padding: '6px',
      imgs: [{
        src: '/images/wheels/cny/bg1.png',
        top: '0px',
        left: '0px',
        width: '100%',
        height: '100%',
        rotate: true
      }]
    },
    {
      padding: '0px',
      imgs: [{
        src: '/images/wheels/cny/bg2.png',
        top: '-30px',
        left: '0px',
        width: '80%',
        height: '80%',
        rotate: false
      }]
    },
  ],
  altPrizeColors: ['#FFCB05', '#ECC82B']
}

export default wheelConfig;
