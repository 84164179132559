import React, { useEffect, useState, useRef, useMemo, useCallback } from 'react';
import { LuckyWheel } from '@lucky-canvas/react';
import Box from '@mui/material/Box';
import { get, map } from 'lodash';
import Decimal from 'decimal.js';
import Paper from '@mui/material/Paper';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Button from '@mui/material/Button';
import { useParams } from "react-router-dom";
import axios from 'axios';
import TextField from '@mui/material/TextField';
import Chance from 'chance';
import Color from 'color';
import { useTheme } from '@mui/material/styles';

const _HOST = process.env.REACT_APP_API_HOST || 'localhost:3030';

export default function LuckySpin(props) {
  const theme = useTheme();
  const myLucky = useRef();
  const params = useParams();
  const [ rewardLabels, setRewardLabels ] = useState("0, 0, 0, 0, 0, 0, 0, 0");
  const [ state, setState ] = useState('idle');
  const [ error, setError ] = useState(null);
  const [ contact, setContact ] = useState('');
  const [ reward, setReward ] = useState(null);
  const [ success, setSuccess ] = useState(null);
  const [ submitted, setSubmitted ] = useState(false);
  const altPrizeColors = get(theme, 'custom.wheelConfig.altPrizeColors', []);
  const wheelBlocks = get(theme, 'custom.wheelConfig.blocks', []);
  const wheelBackground = get(theme, 'custom.wheelConfig.background');

  const apiPort = useMemo(
    () => {
      return get(params, 'apiPort');
    }, [params]
  );

  const luckySpinId = useMemo(
    () => {
      return get(params, 'luckySpinId');
    }, [params]
  );

  const ticketId = useMemo(
    () => {
      return get(params, 'ticketId');
    }, [params]
  );

  const apiHost = useMemo(
    () => {
      if (!apiPort) return _HOST;
      return _HOST.replace('{{PORT}}', apiPort);
    }, [apiPort]
  );

  useEffect(
    () => {
      if (!luckySpinId) return;

      async function fetch() {
        const data = await axios({
          method: 'get',
          url: `${apiHost}/lucky-spin-settings/${luckySpinId}`
        });

        const rewards = get(data, 'data.rewards');
        setRewardLabels(rewards);
      }

      fetch();

    }, [luckySpinId, apiHost]
  );

  const config = useMemo(
    () => {
      return {
        /*buttons: [
          { radius: '25%', background: deepPurple[100] },
          { radius: '20%', background: deepPurple[300] },
          {
            radius: '15%', background: deepPurple[800],
            pointer: true,
            fonts: [{ text: '', top: '-10px', fontColor: 'white', fontSize: '14px' }]
          }
        ],*/
        blocks: wheelBlocks,
        defaultStyle: {
          /*fontSize: '20px'*/
        },
        activeStyle: {
        },
        defaultConfig: {
          speed: 10,
          accelerationTime: 500,
          decelerationTime: 1500,
          offsetDegree: 22.5,
          stopRange: 0.8
        }
      };
    }, [wheelBlocks]
  );

  const prizes = useMemo(() => {
    const labels = rewardLabels.split(',').map(v => (v.trim()));

    const mappedPrizes = map(labels, function(cashReward, index) {
      const fonts = [{
        text: `$${cashReward}`,
        top: '25px',
        fontSize: '1rem',
        fontColor: altPrizeColors[index % altPrizeColors.length],
        fontWeight: 700,
        fontStyle: 'sans-serif'
      }];

      return {
        top: '0px',
        //background: isAlt ? red[500] : deepPurple[100],
        fonts
      };
    });

    return mappedPrizes;
  }, [rewardLabels, altPrizeColors]);

  useEffect(() => {
    if (state !== 'spin' || !luckySpinId || !ticketId || !contact) return;

    setSubmitted(true);

    async function call() {
      try {
        const data = await axios({
          method: 'post',
          url: `${apiHost}/lucky-spins`,
          data: {
            luckySpinId,
            ticketId,
            contact,
          }
        });

        const rewardAmount = get(data, 'data.reward.$numberDecimal', '0');
        setReward(new Decimal(rewardAmount).toNumber());
      } catch (err) {
        const errorMessage = get(err, 'response.data.message', 'Failed to spin due to unknown error!');
        setError(errorMessage);
      } finally {
        setState('idle');
      }
    }

    call();
  }, [state, luckySpinId, ticketId, contact, apiHost]);

  const rewardIndex = useMemo(
    () => {
      if (!reward) return -1;

      const rewards = rewardLabels.split(',').map(v => new Decimal(v.trim()).toNumber());
      const matchedIndex = rewards.reduce(function (all, current, index) {
        const decCurrent = new Decimal(current);
        if (!decCurrent.eq(reward))  return all;
        return [...all, index];
      }, []);

      if (!matchedIndex.length) return -1;

      const chance = new Chance();
      const ret = chance.pickone(matchedIndex);
      return ret;
    }, [reward, rewardLabels]
  );

  useEffect(() => {
    if (rewardIndex < 0) return;

    myLucky.current && myLucky.current.play();

    setTimeout(() => {
      myLucky.current && myLucky.current.stop(rewardIndex);
    }, 5000);

  }, [rewardIndex]);

  const handleSpinClicked = useCallback(
    (event) => {
      if (submitted) return;

      setError(null);
      setReward(null);

      setState(prev => {
        return prev === 'idle' ? 'spin' : prev;
      });
    }, [submitted]
  );

  const handleContactChanged = useCallback(
    (event) => {
      const value = get(event, 'target.value', '');
      setContact(value);
    }, []
  );

  const onSpinEnded = useCallback(
    () => {
      const decReward = new Decimal(reward);
      setSuccess(`You won $${decReward.toFixed(2)}!`);
    }, [reward]
  );

  const wheelBgColor = useMemo(
    () => {
      const themeName = get(theme, 'custom.name');
      const mainColor = themeName === 'blue' ? '#FFFFFF' : theme.palette.primary.main;
      const mainGradient = Color(mainColor).fade(0.8);

      const ret = `linear-gradient(160deg, ${mainGradient.darken(0.1)} 0%, ${mainGradient} 35%, ${mainGradient.lighten(0.3)} 100%)`;
      return ret;
    }, [theme]
  );

  return (
    <Box sx={{ maxWidth: 'sm', mx: 'auto' }}>
      <Box sx={{
        m: 2,
        py: 2,
        background: wheelBgColor,
        ...(
          !!wheelBackground && {
            backgroundImage: `url("${wheelBackground}")`,
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            //backgroundRepeat: 'no-repeat',
          }
        )
      }}>
        <Box sx={{
          my: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
          width: '300px',
          height: '300px',
          mx: 'auto',
        }}>
          <Box sx={{ textAlign: 'center', mb: 1, position: 'absolute', right: 0, top: 0, zIndex: 20 }}>
          </Box>
          <LuckyWheel
            ref={myLucky}
            width="300px"
            height="300px"
            blocks={config.blocks}
            prizes={prizes}
            buttons={config.buttons}
            defaultConfig={config.defaultConfig}
            defaultStyle={config.defaultStyle}
            activeStyle={config.activeStyle}
            /*onStart={handleSpinClicked}*/
            onEnd={onSpinEnded}
          >
          </LuckyWheel>
          <Box sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              visibility: 'none',
              width: '2rem',
              height: '2rem'
            }} onClick={handleSpinClicked}>
          </Box>
        </Box>

        <Box sx={{ mx: 8, p: 1 }}>
          <Box sx={{ bgcolor: 'white', borderRadius: 1 }}>
            <TextField
              disabled={submitted}
              error={!contact}
              helperText={!contact ? 'Required *' : ''}
              fullWidth
              label="Contact"
              id="contact"
              value={contact}
              onChange={handleContactChanged}
              variant="filled"
              size="large"
              placeholder='Mobile Number / Game ID'
            />
          </Box>
          <Paper sx={{ mt: 2 }}>
            <Button
              disabled={submitted}
              onClick={handleSpinClicked}
              sx={{
                bgcolor: '#980104',
                color: '#FFCB05',
                fontSize: '1.5em',
                fontWeight: 700,
                border: '2px #FFCB05 solid',
                borderRadius: '4px',
              }}
              fullWidth
              variant='contained'
            >
              SPIN
            </Button>
          </Paper>
          {
            !!success && <Box sx={{ mt: 2 }}>
              <Alert severity='success'>
                <AlertTitle>🎉 Congrats!</AlertTitle>
                {success}
              </Alert>
            </Box>
          }
          {
            !!error && <Box sx={{ mt: 2 }}>
              <Alert severity='error'>
                <AlertTitle>Error</AlertTitle>
                {error}
              </Alert>
            </Box>
          }
        </Box>
      </Box>
    </Box>
  );
}
